
import { Vue, Component } from "vue-property-decorator";
import { ObjectDataSource } from "@/data/Object/ObjecDatatSource";
import CertificateEditor from "@/components/certificate-editor.vue";
import ScheduleEditor from "@/pages/manage/events/components/schedule-editor/index.vue";
@Component({
  components: { CertificateEditor, ScheduleEditor },
})
export default class EventPage extends Vue {
  dataSource: ObjectDataSource = new ObjectDataSource({
    id: +this.$route.params.broadcastId,
    config: {},
    className: "EventBroadcast",
  });

  eventObjectDataSource = new ObjectDataSource({
    id: +this.$route.params.eventId,
    config: {},
    className: "event",
  });

  scheduleDataSource: ObjectDataSource = new ObjectDataSource({
    id: 0,
    config: {},
    className: "EventSchedule",
  });

  loaded: boolean = false;
  get caption() {
    return this.dataSource.model?.caption;
  }

  async created() {
    await this.eventObjectDataSource.get();
    this.scheduleDataSource.id =
      this.eventObjectDataSource.model.defaultScheduleId;

    await this.scheduleDataSource.get();
    this.loaded = true;
  }

  get breadcrumbs() {
    return [
      {
        text: "Главная",
        to: "/",
      },
      {
        text: "События",
        to: "/manage/events/",
        exact: true,
      },
      {
        text: this.eventObjectDataSource.model?.caption,
        to: `/manage/events/${this.$route.params.eventId}`,
        exact: true,
      },
      {
        text: "Залы трансляции",
        to: `/manage/event/${this.$route.params.eventId}/event-schedules`,
        exact: true,
      },
      {
        text: this.caption,
        disaable: true,
      },
    ];
  }
}
